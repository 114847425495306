import React, { useState } from "react"
import { Button } from "./Button"
import { SAL_PROPS } from "../constants"
import {
  utm_campaign,
  utm_content,
  utm_medium,
  utm_source,
  utm_term,
  ip,
  ip_as_num,
} from "../components/Init"
import { RECAPTCHA_SITE_KEY, ENV, MAILING_LIST_FORM_URL } from "../constants"

export const MailingListInput = ({
  grayscale,
  className,
  placeholder = "Sign up for our newsletter",
}) => {
  const [error, setError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [success, setSuccess] = useState()
  const [email, setEmail] = useState("")
  const [emailLock, setEmailLock] = useState(false)
  const emailValid = /^.+@.+\..+$/.test(email)

  // useEffect(() => {
  //   setSuccess(!!localStorage.getItem("sequence-mailing-list"))
  // }, [])

  const onChange = e => {
    setEmail(e.target.value)
    setError(false)
    setEmailError(false)
  }

  const onKeyPress = e => {
    e.key === "Enter" && onSubmit()
  }

  const onSubmit = () => {
    if (emailLock) {
      return
    }
    if (emailValid && !emailLock) {
      setEmailLock(true)
      setSuccess(true)
      setError(false)
      setEmailError(false)
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(RECAPTCHA_SITE_KEY[ENV], { action: "submit" })
          .then(function (token) {
            submitEmail(
              email,
              token,
              d => {
                setSuccess(true)
                setEmailLock(false)
              },
              e => {
                setSuccess(false)
                setError(true)
                setEmailLock(false)
              }
            )
            setEmail("")
          })
      })
    } else {
      setError(false)
      setEmailError(true)
      setEmailLock(false)
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        top: success ? 0 : 20,
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          color: "#ffffffaa",
          pointerEvents: "none",
          opacity: success ? 1 : 0,
        }}
      >
        <p>
          Thanks for joining our newsletter. You’ll be the first to hear about
          upcoming news and releases!
        </p>
      </div>
      <div
        className={`self-stretch mb-3 flex overflow-hidden ${className}`}
        style={{
          borderRadius: "8px 32px 32px 8px",
          minWidth: 200,
          height: 50,
          pointerEvents: success ? "none" : "auto",
          border:
            error || emailError ? "1px solid #7E4040" : "1px solid transparent",
          opacity: success ? 0 : 1,
          background: grayscale
            ? "#2D2D2D"
            : "linear-gradient(263.6deg, rgba(0, 0, 0, 0) 78.27%, rgba(92, 47, 115, 0.75) 123.46%), #1F1F1F",
        }}
      >
        <input
          className="w-full bg-transparent outline-none text-white"
          value={email}
          onChange={onChange}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          style={{ minWidth: 50, paddingRight: 8 }}
        />

        <Button
          className="block"
          variant={grayscale ? "contained" : "button-gradient"}
          Component="button"
          onClick={onSubmit}
          disabled={emailLock}
          style={{ width: 100, padding: "10px 20px" }}
        >
          Join
        </Button>
      </div>

      <span
        style={{ opacity: error || emailError ? 0.6 : 0 }}
        className="error"
      >
        Oops!{" "}
        {emailError
          ? "Please choose a valid email"
          : "Something went wrong. Please try again later"}
      </span>
    </div>
  )
}

export const MailingListSection = ({ className }) => (
  <div
    {...SAL_PROPS}
    id="newsletter"
    className={`mail-gradient flex flex-col lg:flex-row lg:items-center justify-between rounded px-5 md:px-6 lg:px-10 ${className}`}
  >
    <h3 className="gradient text-center lg:text-left">
      Stay up to date with Sequence
    </h3>
    <div className="my-4" />
    <div className="flex-1 lg:ml-10 lg:pl-5 lg:mr-6">
      <MailingListInput />
    </div>
  </div>
)

const submitEmail = (email, token, onSuccess, onError) => {
  fetch(`${MAILING_LIST_FORM_URL[ENV]}`, {
    method: "POST",
    redirect: "follow",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      utm_content,
      utm_campaign,
      utm_source,
      utm_medium,
      utm_term,
      referrer: document.referrer,
      ip,
      ip_as_num,
      token,
    }),
  })
    .then(onSuccess)
    .catch(onError)
}
