import UniswapPng from "./assets/hero/Uniswap.png"
import OpenSeaPng from "./assets/hero/OpenSea.png"
import SkyweaverPng from "./assets/hero/Skyweaver.png"
import ETHPng from "./assets/hero/eth.png"
import DecentralandPng from "./assets/hero/Decentraland.png"
import MakerDAOPng from "./assets/hero/MakerDAO.png"
import AxielPng from "./assets/hero/Axiel.png"
import SuperRarePng from "./assets/hero/SuperRare.png"
import SandboxPng from "./assets/hero/Sandbox.png"
import CurvePng from "./assets/hero/Curve.png"

export const ENV = process.env.ENV || "dev"

export const VIDEO_URL =
  "https://s3.amazonaws.com/sequence.build/assets/sdk.mp4"

export const RECAPTCHA_SITE_KEY = {
  dev: "6LdhuZUaAAAAAPUxii44mRX4KcbSxZozQ_TD0tyc", // local.0xhorizon.net
  prod: "6LdWWKYaAAAAAIjwODS268oH-r6F_-F82QNATuHR", // sequnece.build
}

// Join our newsletter form submission URL. This will push emails to a Google Sheet
export const MAILING_LIST_FORM_URL = {
  dev:
    "https://script.google.com/macros/s/AKfycbxSnrqTn2lQfvbFX0bJ1P33DIHKRDO1egYb8ksdkUpxf0h0mkH4ulaQ8ThkJ0-qr5kw/exec",
  prod:
    "https://script.google.com/macros/s/AKfycbzmF1G1uqVQjl5mxZKhLzMlPE-a74G5PK0KUZ-KjCJHhpgopTSD75zgeQE8ZQ2_lXfQMw/exec",
}

// Lead gen form submission URL. This will push emails to a Google Sheet
export const LEAD_GEN_FORM_URL = {
  dev:
    "https://script.google.com/macros/s/AKfycbzSajZg3uECoTwfA7hr8BjRuWeh8mkX23oz0VYoHyTJHQGM9C6Jp0gyrucRUbCJPCb7bQ/exec",
  prod:
    "https://script.google.com/macros/s/AKfycby9GltE_k2F_piw27qngWsVr6lbXf4nx1YI7VE9CSazVhdU81Lw1zVRCXZb-_lQ0ePDHw/exec",
}

export const SAL_PROPS = {
  "data-sal": "slide-up",
  "data-sal-duration": "800",
  "data-sal-delay": "200",
}

export const URIS = {
  github: "https://github.com/0xsequence",
  discord: "https://chat.sequence.build",
  twitter: "https://twitter.com/0xsequence",
  contact: "/#contact",
  privacy: "https://sequence.build/privacy",
  terms: "https://sequence.build/terms",
  horizon: "https://horizon.io/",
  docs: "https://docs.sequence.build",
  learn: "https://sequence.info/",
  wallet: "https://sequence.app",
}

export const NAV_LINKS = {
  Docs: URIS.docs,
  Github: URIS.github,
  Discord: URIS.discord,
  Contact: URIS.contact,
}

export const FEATURES = [
  {
    label: "Smart wallet built for a multi-chain world",
    description:
      "The Sequence Wallet empowers your users with a friendly and secure experience for all of Ethereum. Plus, you'll never get stuck: you and your users can easily move between chains to capitalize on the lowest gas fees and highest security.",
  },

  {
    label: "Web & backend SDKs",
    description:
      "Powerful developer libraries and APIs that seamlessly integrate with Ethereum.",
  },

  {
    label: "Audited smart contract library",
    description:
      "A standard library of audited smart contracts. Check out our Github.",
    uri: "https://github.com/0xsequence",
  },

  {
    label: "Enhanced transactions",
    description:
      "Meta transaction support means users can batch transactions and pay for gas in the token of their choice. And, Sequence meta transactions work with all existing contracts (without any changes required to your contracts).",
  },

  {
    label: "Easy minting, transfer and exchange of NFTs and digital items",
    description:
      "Our widely-adopted ERC-1155 smart contracts help you easily create digital collectibles. Plus, enhance your capabilities with our NFT APIs for querying metadata and balances.",
  },

  {
    label: "Social / email login & recovery for wallets",
    description:
      "Easily sign in via Google, Facebook or other socials. No blockchain knowledge required.",
  },

  {
    label: "Niftyswap (NFT) market protocol",
    description:
      "Offer your users an ecommerce-like market experience for trading ERC-1155 tokens. Users will love instant liquidity with Niftyswap's AMM design! Build your own custom UI's — we've already built the backend for you.",
  },

  {
    label: "Friendly payments",
    description:
      "Easy credit card on-ramps, and your users don’t need to own crypto to pay fees.",
  },
]

const HERO_BRANDS = {
  Uniswap: { label: "Uniswap", href: "https://uniswap.org/", src: UniswapPng },
  OpenSea: { label: "OpenSea", href: "https://opensea.io/", src: OpenSeaPng },
  Skyweaver: {
    label: "Skyweaver",
    href: "https://skyweaver.net/",
    src: SkyweaverPng,
  },
  ETH: { label: "Ethereum", href: "https://ethereum.org/en/", src: ETHPng },
  Decentraland: {
    label: "Decentraland",
    href: "https://decentraland.org/",
    src: DecentralandPng,
  },
  MakerDAO: {
    label: "MakerDAO",
    href: "https://makerdao.com/en/",
    src: MakerDAOPng,
  },
  AxieInfinity: {
    label: "AxieInfinity",
    href: "https://axieinfinity.com/",
    src: AxielPng,
  },
  Superrare: {
    label: "Superrare",
    href: "https://superrare.co/",
    src: SuperRarePng,
  },
  TheSandbox: {
    label: "The Sandbox",
    href: "https://www.sandbox.game/en/",
    src: SandboxPng,
  },
  CurveFinance: {
    label: "Curve Finance",
    href: "https://www.curve.fi/",
    src: CurvePng,
  },
}

export const HERO_IMAGE_SWAP_DURATION = 5000
export const HERO_IMAGES = [
  [HERO_BRANDS.Uniswap, HERO_BRANDS.Skyweaver],
  [HERO_BRANDS.OpenSea, HERO_BRANDS.AxieInfinity],
  [HERO_BRANDS.Superrare, HERO_BRANDS.TheSandbox],
  [HERO_BRANDS.ETH, HERO_BRANDS.MakerDAO],
  [HERO_BRANDS.Decentraland, HERO_BRANDS.CurveFinance],
]
