import React from "react"
import { Helmet } from "react-helmet"
import { RECAPTCHA_SITE_KEY, ENV } from "../../constants"

export const Head = () => (
  <Helmet>
    <title>Sequence</title>

    <meta charset="utf-8" />
    <meta
      property="og:title"
      content="Sequence | developer platform + smart wallet for Ethereum and Web3"
    />
    <meta
      name="description"
      content="Build blockchain applications with ease. Developer platform + smart wallet for Ethereum, NFTs, Web3, DeFi and Dapps."
    />
    <meta
      name="application-name"
      content="Sequence | developer platform + smart wallet for Ethereum and Web3"
    />

    <meta name="robots" content="index,follow" />
    <meta name="googlebot" content="index,follow" />

    <meta property="og:type" content="website" />
    <meta
      property="og:image"
      content="https://sequence.build/sequence-banner2.jpg"
    />
    <meta
      property="og:description"
      content="Build blockchain applications with ease. Developer platform + smart wallet for Ethereum, NFTs, Web3, DeFi and Dapps."
    />
    <meta
      property="og:site_name"
      content="Sequence | developer platform + smart wallet for Ethereum and Web3"
    />
    <meta property="og:locale" content="en_US" />

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@0xsequence" />
    <meta
      name="twitter:title"
      content="Sequence | developer platform + smart wallet for Ethereum and Web3"
    />
    <meta
      name="twitter:description"
      content="Build blockchain applications with ease. Developer platform + smart wallet for Ethereum, NFTs, Web3, DeFi and Dapps."
    />
    <meta
      name="twitter:image"
      content="https://sequence.build/sequence-banner2.jpg"
    />

    <link
      href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap"
      rel="stylesheet"
    />
    <script
      src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY[ENV]}`}
    ></script>

    <script
      defer
      src="https://static.cloudflareinsights.com/beacon.min.js"
      data-cf-beacon='{"token": "598ee3a4ac1a4176b69422e735eebde4"}'
    ></script>

    <script async src="https://www.googletagmanager.com/gtag/js?id=G-TVQ787L809"></script>
    <script>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-TVQ787L809');
      `}
    </script>
  </Helmet>
)
