import React from "react"

export const Button = ({
  children,
  variant = "button-gradient",
  className = "",
  Component = "a",
  href = '/#',
  target = '_blank',
  ...props
}) => {
  const textColorClass = className.match(/text-/) ? "" : "text-medium-gray"

  if (variant === "outlined") {
    return (
      <div className={`outlined-wrap ${className}`}>
        <Component
          href={href}
          target={target}
          className={`button ${variant} ${textColorClass}`}
          {...props}
        >
          <span className="relative">{children}</span>
        </Component>
      </div>
    )
  }

  return (
    <Component
      href={href}
      target={target}
      className={`button ${variant} ${className} ${textColorClass}`}
      {...props}
    >
      <span className="relative">{children}</span>
    </Component>
  )
}
