export let utm_source = ''
export let utm_campaign = ''
export let utm_medium = ''
export let utm_content = ''
export let utm_term = ''
export let ip = ''
export let ip_as_num = 0

export const init = () => {
  try {
    const params = new URL(window.location.href).searchParams
    utm_source = params.get('utm_source') || params.get('UTM_SOURCE') || ''
    utm_campaign = params.get('utm_campaign') || params.get('UTM_CAMPAIGN') || ''
    utm_medium = params.get('utm_medium') || params.get('UTM_MEDIUM') || ''
    utm_content = params.get('utm_content') || params.get('UTM_CONTENT') || ''
    utm_term = params.get('utm_term') || params.get('UTM_TERM') || ''
    fetch("https://sequence.build/cdn-cgi/trace").then(res => {
      return res.text()
    }).then(data => {
      const regx = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/
      const ipValue = data.match(regx)
      if (ipValue) {
        const ipAsString = ipValue.toString()
        const ipAsInt = ipAsString.split('.').reduce(function(ipInt, octet) { return (ipInt<<8) + parseInt(octet, 10)}, 0) >>> 0
        ip = ipAsString
        ip_as_num = ipAsInt
      } else {
        console.error('Error parsing IP')
      }
    }).catch(() => {
      console.error('Error fetching IP endpoint')
    })
  } catch (e) {
    // no-op; gatsby compiles server side when client components are not available, so just ignore
  }
}